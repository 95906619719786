<template>
  <Dialog custom-class="confirmation" title="Uklanjanje" :visible="isRemoveDialogVisible" :show-close="false">
    <div class="message">
      Jeste li sigurni?
    </div>
    <div class="form">
      <Form ref="componentForm" @submit.native.prevent="submitForm('componentForm')">
        <FormItem>
          <Button
            class="btn-grey"
            icon="el-icon-close"
            @click="$emit('closeDialog')"/>
          <Button
            native-type="submit"
            class="btn-light"
            icon="el-icon-check"
            :loading="loading"/>
        </FormItem>
      </Form>
    </div>
  </Dialog>
</template>

<script>
// Mixins
import {Common} from '@/mixins/Common';

// Element UI
import {Form, FormItem, Button, Dialog} from 'element-ui';

// Logic
export default {
  name: 'AdministrationInsurancesDetailModalsRemove',
  mixins: [Common],
  components: {
    Form,
    FormItem,
    Button,
    Dialog
  },
  props: [
    'isRemoveDialogVisible',
    'item'
  ],
  methods: {
    submitForm() {
      this.$emit('remove',{
        id: this.formData.id,
        type: this.formData.type,
        name: this.formData.name,
        cost: this.formData.cost,
        quantityFrom: this.formData.quantityFrom,
        quantityTo: this.formData.quantityTo,
        costRadiusTo20: this.formData.costRadiusTo20,
        costRadiusFrom20To35: this.formData.costRadiusFrom20To35,
        costRadiusFrom35To50: this.formData.costRadiusFrom35To50
      });
      this.$emit('closeDialog');
    }
  },
  watch: {
    item() {
      this.formData = {
        id: this.item.id,
        type: this.item.type,
        name: this.item.name,
        cost: this.item.cost,
        quantityFrom: this.item.quantityFrom,
        quantityTo: this.item.quantityTo,
        costRadiusTo20: this.item.costRadiusTo20,
        costRadiusFrom20To35: this.item.costRadiusFrom20To35,
        costRadiusFrom35To50: this.item.costRadiusFrom35To50
      };
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/scss/core/_variables.scss';
@import '@/assets/scss/mixins/_functions.scss';
@import '@/assets/scss/mixins/_breakpoints.scss';
@import '@/assets/scss/elements/_buttons.scss';
</style>