<template>
  <Dialog title="Novi zapis" :visible="isCreateDialogVisible" :show-close="false">
    <div class="form">
      <Form :model="formData" :rules="rules" ref="componentForm" @submit.native.prevent="validateForm('componentForm')">
        <FormItem prop="type" label="Tip oštečenja">
          <Select v-model="formData.type">
            <Option :label="'Tuča'" :value="'hail'"></Option>
            <Option :label="'Parking'" :value="'parking'"></Option>
          </Select>
        </FormItem>
        <FormItem prop="name" label="Naziv" :class="{ 'd-none': isHail }">
          <Input
              v-model="formData.name"/>
        </FormItem>
        <FormItem prop="cost" label="Cijena" :class="{ 'd-none': isHail }">
          <InputNumber
              :min="1"
              precision="2"
              controls-position="right"
              v-model="formData.cost"/>
        </FormItem>
        <Row :gutter="16" type="flex">
          <Col>
            <FormItem prop="quantityFrom" label="Broj udubina od" :class="{ 'd-none': !isHail }">
              <InputNumber
                  :min="1"
                  controls-position="right"
                  v-model="formData.quantityFrom"/>
            </FormItem>
          </Col>
          <Col>
            <FormItem prop="quantityTo" label="Broj udubina do" :class="{ 'd-none': !isHail }">
              <InputNumber
                  :min="formData.quantityFrom"
                  controls-position="right"
                  v-model="formData.quantityTo"/>
            </FormItem>
          </Col>
        </Row>
        <FormItem prop="costRadiusTo20" label="Cijena za promjer udubina do 20mm" :class="{ 'd-none': !isHail }">
          <InputNumber
              :min="1"
              precision="2"
              controls-position="right"
              v-model="formData.costRadiusTo20"/>
        </FormItem>
        <FormItem prop="costRadiusFrom20To35" label="Cijena za promjer udubina 20-35mm" :class="{ 'd-none': !isHail }">
          <InputNumber
              :min="1"
              precision="2"
              controls-position="right"
              v-model="formData.costRadiusFrom20To35"/>
        </FormItem>
        <FormItem prop="costRadiusFrom35To50" label="Cijena za promjer udubina 35-50mm" :class="{ 'd-none': !isHail }">
          <InputNumber
              :min="1"
              precision="2"
              controls-position="right"
              v-model="formData.costRadiusFrom35To50"/>
        </FormItem>
        <FormItem>
          <Button
              class="btn-grey"
              icon="el-icon-close"
              @click="$emit('closeDialog')"/>
          <Button
              native-type="submit"
              class="btn-light"
              icon="el-icon-check"
              :loading="loading"/>
        </FormItem>
      </Form>
    </div>
  </Dialog>
</template>

<script>
// Mixins
import {Common} from '@/mixins/Common';

// Element UI
import {Form, FormItem, Select, Option, Input, InputNumber, Row, Col, Button, Dialog} from 'element-ui';

// Logic
export default {
  name: 'AdministrationInsurancesDetailModalsCreate',
  mixins: [Common],
  components: {
    Form,
    FormItem,
    Select,
    Option,
    Input,
    InputNumber,
    Row,
    Col,
    Button,
    Dialog
  },
  props: [
    'isCreateDialogVisible'
  ],
  data() {
    return {
      formData: {
        type: 'hail',
        name: '',
        cost: 1,
        quantityFrom: 1,
        quantityTo: 1,
        costRadiusTo20: 1,
        costRadiusFrom20To35: 1,
        costRadiusFrom35To50: 1,
      },
      rules: {
        type: [
          {
            required: true,
            message: 'Molimo ispunite polje',
            trigger: ['blur']
          }
        ],
        name: [
          {
            required: true,
            message: 'Molimo ispunite polje',
            trigger: ['blur']
          }
        ],
        cost: [
          {
            required: true,
            message: 'Molimo ispunite polje',
            trigger: ['blur']
          }
        ],
        quantityFrom: [
          {
            required: true,
            message: 'Molimo ispunite polje',
            trigger: ['blur']
          }
        ],
        quantityTo: [
          {
            required: true,
            message: 'Molimo ispunite polje',
            trigger: ['blur']
          }
        ],
        costRadiusTo20: [
          {
            required: true,
            message: 'Molimo ispunite polje',
            trigger: ['blur']
          }
        ],
        costRadiusFrom20To35: [
          {
            required: true,
            message: 'Molimo ispunite polje',
            trigger: ['blur']
          }
        ],
        costRadiusFrom35To50: [
          {
            required: true,
            message: 'Molimo ispunite polje',
            trigger: ['blur']
          }
        ]
      }
    }
  },
  computed: {
    isHail() {
      return this.formData.type === 'hail';
    }
  },
  methods: {
    validateForm(formName) {
      if (this.formData.type === 'parking') {
        this.$refs[formName].validate((valid) => {
          if (valid) {
            this.submitForm(formName);
          } else {
            return false;
          }
        });
      } else {
        this.submitForm(formName);
      }
    },
    submitForm(formName) {
      this.$emit('create', {
        id: this.generateUUID(),
        type: this.formData.type,
        name: this.formData.name,
        cost: this.formData.cost,
        quantityFrom: this.formData.quantityFrom,
        quantityTo: this.formData.quantityTo,
        costRadiusTo20: this.formData.costRadiusTo20,
        costRadiusFrom20To35: this.formData.costRadiusFrom20To35,
        costRadiusFrom35To50: this.formData.costRadiusFrom35To50
      });
      this.$emit('closeDialog');
      this.setData(formName);
    },
    setData(formName) {
      this.$refs[formName].resetFields();
    },
    generateUUID() {
      let d1 = new Date().getTime();
      let d2 = ((typeof performance !== undefined) && performance.now && (performance.now() * 1000)) || 0;
      return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
        let r = Math.random() * 16;
        if (d1 > 0) {
          r = (d1 + r) % 16 | 0;
          d1 = Math.floor(d1 / 16);
        } else {
          r = (d2 + r) % 16 | 0;
          d2 = Math.floor(d2 / 16);
        }
        return (c === 'x' ? r : (r & 0x3 | 0x8)).toString(16);
      });
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/scss/core/_variables.scss';
@import '@/assets/scss/mixins/_functions.scss';
@import '@/assets/scss/mixins/_breakpoints.scss';
@import '@/assets/scss/elements/_buttons.scss';
</style>
