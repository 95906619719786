<template>
  <div class="insurances-detail">
    <div class="headline">
      <h1 class="title">
        {{ operatingItem.content.name }}
      </h1>
      <div class="action">
        <Button class="btn-primary" @click.prevent="isCreateDialogVisible = true">
          <i class="el-icon-plus"></i>
        </Button>
      </div>
    </div>
    <div class="items" v-if="hailList.length">
      <div class="item">
        <div class="quantity">
          Broj udubina
        </div>
        <div class="cost-radius-to-20">
          Cijena do 20mm
        </div>
        <div class="cost-radius-from-20-to-35">
          Cijena od 20-35mm
        </div>
        <div class="cost-radius-from-35-to-50">
          Cijena od 35-50mm
        </div>
        <div class="action"></div>
      </div>
      <div class="item" v-for="(item, index) in hailList" :key="index">
        <div class="quantity">
          <span v-if="item.quantityFrom === item.quantityTo">
            {{ item.quantityFrom }}
          </span>
          <span v-else>
            {{ item.quantityFrom }} - {{ item.quantityTo }}
          </span>
        </div>
        <div class="cost-radius-to-20">
          {{ parseFloat(item.costRadiusTo20).toFixed(2) }} {{ itemCurrencyByDate(operatingItem.createdAt) }}
        </div>
        <div class="cost-radius-from-20-to-35">
          {{ parseFloat(item.costRadiusFrom20To35).toFixed(2) }} {{ itemCurrencyByDate(operatingItem.createdAt) }}
        </div>
        <div class="cost-radius-from-35-to-50">
          {{ parseFloat(item.costRadiusFrom35To50).toFixed(2) }} {{ itemCurrencyByDate(operatingItem.createdAt) }}
        </div>
        <div class="action">
          <Button class="btn-light" @click="updateItem(item)">
            <i class="el-icon-edit"></i>
          </Button>
          <Button class="btn-light" @click="removeItem(item)">
            <i class="el-icon-close"></i>
          </Button>
        </div>
      </div>
    </div>
    <div class="items" v-if="parkingList.length">
      <div class="item">
        <div class="name">
          Naziv
        </div>
        <div class="cost">
          Cijena
        </div>
        <div class="action"></div>
      </div>
      <div class="item" v-for="(item, index) in parkingList" :key="index">
        <div class="name">
          {{ item.name }}
        </div>
        <div class="cost">
          {{ parseFloat(item.cost).toFixed(2) }} {{ itemCurrencyByDate(operatingItem.createdAt) }}
        </div>
        <div class="action">
          <Button class="btn-light" @click="updateItem(item)">
            <i class="el-icon-edit"></i>
          </Button>
          <Button class="btn-light" @click="removeItem(item)">
            <i class="el-icon-close"></i>
          </Button>
        </div>
      </div>
    </div>
    <AdministrationInsurancesDetailModalsCreate
      :isCreateDialogVisible="isCreateDialogVisible"
      @closeDialog="isCreateDialogVisible = false"
      @create="create"/>
    <AdministrationInsurancesDetailModalsUpdate
      :isUpdateDialogVisible="isUpdateDialogVisible"
      :item="item"
      @closeDialog="isUpdateDialogVisible = false"
      @update="update"/>
    <AdministrationInsurancesDetailModalsRemove
      :isRemoveDialogVisible="isRemoveDialogVisible"
      :item="item"
      @closeDialog="isRemoveDialogVisible = false"
      @remove="remove"/>
  </div>
</template>

<script>
// Mixins
import {Common} from '@/mixins/Common';

// Components
import AdministrationInsurancesDetailModalsCreate from '@/views/Administration/Insurances/Detail/Modals/Create';
import AdministrationInsurancesDetailModalsUpdate from '@/views/Administration/Insurances/Detail/Modals/Update';
import AdministrationInsurancesDetailModalsRemove from '@/views/Administration/Insurances/Detail/Modals/Remove';

// Element UI
import {Row, Col, Button} from 'element-ui';

// Logic
export default {
  name: 'AdministrationInsurancesDetailIndex',
  mixins: [Common],
  components: {
    AdministrationInsurancesDetailModalsCreate,
    AdministrationInsurancesDetailModalsUpdate,
    AdministrationInsurancesDetailModalsRemove,
    Row,
    Col,
    Button
  },
  data() {
    return {
      isCreateDialogVisible: false,
      isUpdateDialogVisible: false,
      isRemoveDialogVisible: false,
      operatingItem: {
        id: '',
        content: {
          name: '',
          list: []
        }
      },
      limitDate: '2024-02-16',
      item: null,
    }
  },
  computed: {
    hailList() {
      return this.operatingItem.content.list.filter(item => item.type === 'hail');
    },
    parkingList() {
      return this.operatingItem.content.list.filter(item => item.type === 'parking');
    }
  },
  methods: {
    getOperatingItem() {
      this.setLoadingComponent(true);
      this.Axios.get(`administration/insurance/${this.$route.params.id}/get`).then(response => {
        if (response.data.content.data) {
          this.operatingItem = {
            id: response.data.content.data.id,
            content: {
              name: response.data.content.data.content.name,
              list: response.data.content.data.content.list
            },
            createdAt: response.data.content.data.created_at
          };
        }
      }).catch(error => {
        if (error.response) {
          this.message = error.response.data.content.message;
          this.status = error.response.data.content.status;
          this.operatingItem = {
            id: '',
            content: {
              name: '',
              list: []
            }
          };
        }
      }).finally(() => {
        this.setLoadingComponent(false);
      });
    },
    setOperatingItem() {
      this.setLoadingComponent(true);
      this.Axios.post(`administration/insurance/${this.$route.params.id}/update`, {
        content: JSON.stringify(this.operatingItem.content)
      }).then(response => {
        this.message = response.data.content.message;
        this.status = response.data.content.status;
      }).catch(error => {
        if (error.response) {
          this.message = error.response.data.content.message;
          this.status = error.response.data.content.status;
        }
      }).finally(() => {
        this.setLoadingComponent(false);
        this.setNotification();
      });
    },
    create(data) {
      this.operatingItem.content.list.push(data);
      this.sortByType();
      this.setOperatingItem();
    },
    update(data) {
      this.operatingItem.content.list.splice(this.operatingItem.content.list.findIndex(item => item.id === data.id), 1, data);
      this.sortByType();
      this.setOperatingItem();
    },
    remove(data) {
      this.operatingItem.content.list = this.operatingItem.content.list.filter(item => item.id !== data.id);
      this.sortByType();
      this.setOperatingItem();
    },
    updateItem(item) {
      this.item = item;
      this.isUpdateDialogVisible = true;
    },
    removeItem(item) {
      this.item = item;
      this.isRemoveDialogVisible = true;
    },
    sortByType() {
      this.operatingItem.content.list = this.operatingItem.content.list.sort((a, b) => {
        let x = a.type.toLowerCase();
        let y = b.type.toLowerCase();

        if (x < y) {
          return 1;
        }

        if (x > y) {
          return -1;
        }

        return 0;
      });
    },
    itemCurrencyByDate(date) {
      if (!date)
        return '€';
      return date.slice(0,10) <= this.limitDate ? 'HRK' : '€';
    },
  },
  created() {
    this.initialize();
    this.getOperatingItem();
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/scss/core/_variables.scss';
@import '@/assets/scss/mixins/_functions.scss';
@import '@/assets/scss/mixins/_breakpoints.scss';
@import '@/assets/scss/elements/_buttons.scss';

.insurances-detail {
  .form {
    margin-top: 32px;
    margin-bottom: 48px;
  }

  .items {
    margin-top: 32px;
    margin-bottom: 48px;

    .item {
      display: flex;
      align-items: center;
      border-bottom: 1px solid $grey-100;
      padding-bottom: 8px;
      margin-bottom: 8px;

      &:first-child {
        font-weight: 700;
        padding-bottom: 16px;
      }

      &:last-child {
        border-bottom: 0;
        padding-bottom: 0;
        margin-bottom: 0;
      }

      .name,
      .quantity,
      .cost-radius-to-20,
      .cost-radius-from-20-to-35,
      .cost-radius-from-35-to-50,
      .action {
        width: 20%;
      }

      .cost {
        width: 60%;
      }

      .action {
        text-align: right;
      }

      .action {
        .el-button {
          width: 48px;
          min-width: unset;
          height: 48px;
        }
      }
    }
  }
}
</style>
